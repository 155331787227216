import React from "react"
import { inject } from 'mobx-react'
import { navigate } from "gatsby"


class Product extends React.Component {

  constructor(props) {
    super(props)
    this.countRef = React.createRef()
  }

  render() {
    let { store, title, html, sku, price, path } = this.props
    return (
      <div>
        <h2>{title}</h2>
        <div className="clear-block">
          <div className="node">
            <div className="content clear-block">
              <div className="product-image">
                <div className="main-product-image"><a
                  href="https://web.archive.org/web/20150523073419/http://www.noteimals.com/sites/default/files/imagecache/product_full/Cover%20for%20Note%20Reading%20NO%202.jpg"
                  title="Piano Beginning Note Reading for Children"><img
                  src="https://web.archive.org/web/20150523073419im_/http://www.noteimals.com/sites/default/files/imagecache/product/Cover%20for%20Note%20Reading%20NO%202.jpg"
                  alt="" title="Piano Beginning Note Reading for Children" width="100" height="77"
                  className="imagecache imagecache-product"/></a></div>
              </div>
              <div className="product-info product display"><span
                className="uc-price-product uc-price-display uc-price">${price}</span></div>
              <div className="product-info model">SKU: {sku}</div>
              <div
                className="product-body"
                dangerouslySetInnerHTML={{ __html: html }}
              />
              <div className="product-info product sell"><span className="uc-price-product uc-price-sell uc-price"><span
                className="price-prefixes">Price: </span>${price}</span></div>
              <div className="add-to-cart">
                <div>
                  <div className="form-item" id="edit-qty-wrapper">
                    <label htmlFor="edit-qty">Quantity: <span className="form-required"
                                                              title="This field is required.">*</span></label>
                    <input ref={this.countRef} type="text" maxLength="3" size="5" defaultValue="1"
                           className="form-text required"/>
                  </div>
                  <button aria-label={'Add to Cart'} onClick={() => {
                    alert('We apologize but printed materials are no longer available online.')
                    // console.log(this.countRef.current.value)
                    // store.addItem({
                    //   name: title,
                    //   sku: sku,
                    //   unit_price: price,
                    //   count: +this.countRef.current.value || 1,
                    //   path: path
                    // })
                    // navigate('/cart')
                  }}>Add to Cart
                  </button>

                  <input type="hidden" name="form_build_id" id="form-EOrHaakxcbx-auxRdiGYPEzZ_mUBRPQNmGjkTKfOLK4"
                         value="form-EOrHaakxcbx-auxRdiGYPEzZ_mUBRPQNmGjkTKfOLK4"/>
                  <input type="hidden" name="form_id" id="edit-uc-product-add-to-cart-form-3"
                         value="uc_product_add_to_cart_form_3"/>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default inject('store')(Product)
