import React from "react"
import { Link } from "gatsby"
const ListLink = props => (
  <li className="leaf">
    <Link to={props.to}>{props.children}</Link>
  </li>
)
export default ({ children }) => (
  <ul className="menu">
    <ListLink to="/materials/piano" title="Piano teaching materials">Piano</ListLink>
    <ListLink to="/materials/guitar" title="Guitar teaching materials">Guitar</ListLink>
    <ListLink to="/materials/violin" title="Violin Teaching Materials">Violin</ListLink>
    <ListLink to="/store" title="All Music Teaching Products">All Products</ListLink>
  </ul>
)