import React from "react"
import ParentLayout from "./layout-parent"
import CenterPane from "./layout-center-pane"
import SideBar from "./layout-sidebar"
import MaterialsMenu from "./menu-materials"

export default (props) => (
  <ParentLayout className="sidebar-right" {...props}>
    <CenterPane>
      {props.children}
    </CenterPane>
    <SideBar>
      <MaterialsMenu/>
    </SideBar>
  </ParentLayout>
)
